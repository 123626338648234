// @ts-nocheck
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Alert, Skeleton } from '@mui/material';
import { consultationsByTreatmentPlanId } from 'graphql/querys/consultationsByTreatmentPlanIdQuery';
import { ConsultationsOwedByPatientId } from 'graphql/querys/consultationsOwedByPatientIdQuery';
import { treatmentPlansByPatientId } from 'graphql/querys/treatmentPlansByPatientId';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateConsultationMutation } from '../../../graphql/mutations/updateConsultation';
import { consultationById } from '../../../graphql/querys/consultationByIdQuery';
import { consultationDataFilesQuery } from '../../../graphql/querys/consultationDataFilesQuery';
import { ConsultationsByPatientId } from '../../../graphql/querys/consultationsByPatientIdQuery';
import { generateConsultationDataUploadURL } from '../../../graphql/querys/generateConsultationDataUploadUrlQuery';
import { putS3 } from '../../../utils/s3Actions';
import { toCamelCase } from '../../../utils/toCamelCase';
import { toSnakeCase } from '../../../utils/toSnakeCase';
import { ConsultationForm } from './ConsultationForm';

export function ConsultationDetail() {
  const { id, consultationId } = useParams();
  const [confimEdit, setConfirmEdit] = useState(false);
  const [treatmentPlanId, setTreatmentPlanId] = useState(null);
  const [oldTreatmentPlanId, setOldTreatmentPlanId] = useState(null);
  const { data: consultationData, loading: consultationLoading } = useQuery(consultationById, {
    variables: { consultationByIdId: consultationId }
  });

  const [updateConsultation, { error: consultationUpdateError }] = useMutation(
    updateConsultationMutation,
    {
      // Then re-run
      refetchQueries: [
        {
          query: ConsultationsByPatientId,
          variables: { consultationsByPatientIdId: id }
        },
        { query: consultationById, variables: { consultationByIdId: consultationId } },
        { query: ConsultationsOwedByPatientId, variables: { id } },
        ...(treatmentPlanId !== 'indefinido' && !!treatmentPlanId
          ? [
              {
                query: consultationsByTreatmentPlanId,
                variables: { consultationsByTreatmentPlanIdId: treatmentPlanId }
              },
              {
                query: treatmentPlansByPatientId,
                variables: { id }
              }
            ]
          : []),
        ...(oldTreatmentPlanId !== 'indefinido' && !!oldTreatmentPlanId
          ? [
              {
                query: consultationsByTreatmentPlanId,
                variables: { consultationsByTreatmentPlanIdId: oldTreatmentPlanId }
              },
              {
                query: treatmentPlansByPatientId,
                variables: { id }
              }
            ]
          : [])
      ]
    }
  );
  const [generateConsultationDataUploadURLQuery] = useLazyQuery(generateConsultationDataUploadURL);

  const { refetch: refetchData } = useQuery(consultationDataFilesQuery, {
    variables: { consultationId }
  });

  useEffect(() => {
    setOldTreatmentPlanId(consultationData?.consultationById?.treatment_plan_id);
  }, [consultationData]);

  if (consultationLoading || !consultationData) {
    return <Skeleton />;
  }
  const formValues = toCamelCase(consultationData.consultationById);

  const onSubmit = async (values, files) => {
    const consultationData = toSnakeCase(values);
    consultationData.payed_amount = parseFloat(consultationData.payed_amount);
    consultationData.amount_to_pay =
      parseFloat(consultationData.total_amount) - parseFloat(consultationData.payed_amount);
    consultationData.total_amount = parseFloat(consultationData.total_amount);
    consultationData.event.patient_id = consultationData.patient_id;
    consultationData.event.mandatory_presence = consultationData.doctor;

    delete consultationData.event.id;
    delete consultationData.event.consultation_id;
    delete consultationData.event.created_at;
    delete consultationData.event.created_by;
    delete consultationData.event.updated_at;
    delete consultationData.event.last_update_by;
    delete consultationData.event.typename;

    delete consultationData.consultation_id;
    delete consultationData.patient_id;
    delete consultationData.typename;

    delete consultationData.treatments;
    setTreatmentPlanId(consultationData.treatment_plan_id);

    await updateConsultation({
      variables: { consultationData, updateConsultationId: consultationId }
    });

    const urlData = files;
    const fileData = files.map((a) => a.file);
    urlData.map((file) => delete file.file);

    const urlInput = toSnakeCase(urlData);
    const urls = await generateConsultationDataUploadURLQuery({
      variables: {
        consultationId,
        arrayMetadata: urlInput
      }
    });
    await putS3(fileData, urls.data.generateConsultationDataUploadURL);

    refetchData();

    if (!consultationUpdateError) {
      setConfirmEdit(true);
      setTimeout(() => {
        setConfirmEdit(false);
      }, 4000);
    }
  };
  return (
    <>
      <ConsultationForm values={formValues} onSubmit={onSubmit} patientId={id} />
      {confimEdit && <Alert severity="success">La consulta ha sido editada</Alert>}
    </>
  );
}
