import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert
} from '@mui/material';
import PropTypes from 'prop-types';
import { treatmentPlansByPatientId } from 'graphql/querys/treatmentPlansByPatientId';
import { deleteTreatmentPlanMutation } from 'graphql/mutations/deleteTreatmentPlans';
import { useMutation } from '@apollo/client';
import { Link as RouterLink, useParams } from 'react-router-dom';
import React from 'react';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead } from '../user';
import ContextTreatmentPlanMenu from './ContextTreatmentPlanMenu';

const FirstConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        ¿Está seguro que quiere eliminar este plan de tratamiento?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm}>Eliminar</Button>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
);

const SecondConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Toda la información relacionada a este plan de tratamiento será eliminada y no podrá ser
        recuperada, ¿desea continuar?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm}>Eliminar</Button>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
);

FirstConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

SecondConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

TreatmentPlansTableContent.propTypes = {
  filterName: PropTypes.string,
  filteredTreatmentPlans: PropTypes.array,
  handleRequestSort: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isTreatmentPlansNotFound: PropTypes.bool,
  TABLE_HEAD: PropTypes.array
};

export default function TreatmentPlansTableContent({
  filterName,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredTreatmentPlans,
  page,
  rowsPerPage,
  emptyRows,
  isTreatmentPlansNotFound
}) {
  const { id } = useParams();
  const [
    deleteTreatmentPlan,
    { loading: deleteTreatmentPlanLoading, error: deleteTreatmentPlanError }
  ] = useMutation(deleteTreatmentPlanMutation, {
    refetchQueries: [{ query: treatmentPlansByPatientId, variables: { id } }]
  });

  const [deleteFirstConfirmModal, setDeleteFirstConfirmModal] = React.useState(false);
  const [deleteSecondConfirmModal, setDeleteSecondConfirmModal] = React.useState(false);
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = React.useState(null);
  const [deleteError, setDeleteError] = React.useState(null);
  const [deleteSuccess, setDeleteSuccess] = React.useState(null);

  const handleDeleteResponse = (data) => {
    const { deleteTreatmentPlanError, deleteTreatmentPlanData } = data.deleteTreatmentPlan;

    if (deleteTreatmentPlanError) {
      setDeleteError(
        deleteTreatmentPlanError.field === 'treatment plan deletion'
          ? 'No se puede eliminar el plan de tratamiento ya que algunos tratamientos están asignados a consultas. Elimine estas consultas antes de eliminar el plan de tratamiento.'
          : deleteTreatmentPlanError.message
      );
      return;
    }

    if (deleteTreatmentPlanData) {
      setDeleteError(null);
      setDeleteSuccess(`El plan de tratamiento ha sido eliminado`);
    }
  };

  const handleDeleteSelected = () => {
    setDeleteFirstConfirmModal(false);
    setDeleteSecondConfirmModal(false);
    console.log('selectedTreatmentPlan', selectedTreatmentPlan);
    deleteTreatmentPlan({
      variables: {
        treatmentPlanId: selectedTreatmentPlan
      }
    }).then(({ data }) => handleDeleteResponse(data));
    setSelectedTreatmentPlan(null);
  };

  const handleDelete = (treatmentPlanId) => {
    setSelectedTreatmentPlan(treatmentPlanId);
    setDeleteFirstConfirmModal(true);
  };

  return (
    <>
      {deleteError && (
        <Alert variant="outlined" severity="error">
          {deleteError}
        </Alert>
      )}
      {deleteSuccess && (
        <Alert variant="outlined" severity="success">
          {deleteSuccess}
        </Alert>
      )}
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredTreatmentPlans
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const { name, treatments } = row;
                const treatmentPlanID = row.id;

                return (
                  <TableRow
                    component={RouterLink}
                    to={`/dashboard/treatmentPlan/${id}/${treatmentPlanID}`}
                    sx={{ textDecoration: 'none', color: 'inherit' }}
                    hover
                    key={id}
                    tabIndex={-1}
                  >
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{treatments.length}</TableCell>
                    <TableCell align="left">
                      <ContextTreatmentPlanMenu
                        handleDelete={() => handleDelete(treatmentPlanID)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isTreatmentPlansNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} onClick={(e) => e.stopPropagation()} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Más que ${to}`}`
        }
        labelRowsPerPage="Columnas por pagina"
        count={filteredTreatmentPlans.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <FirstConfirmDialog
        open={deleteFirstConfirmModal}
        onClose={() => setDeleteFirstConfirmModal(false)}
        onConfirm={() => {
          setDeleteFirstConfirmModal(false);
          setDeleteSecondConfirmModal(true);
        }}
      />
      <SecondConfirmDialog
        open={deleteSecondConfirmModal}
        onClose={() => setDeleteSecondConfirmModal(false)}
        onConfirm={handleDeleteSelected}
      />
    </>
  );
}
