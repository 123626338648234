import { LoadingButton } from '@mui/lab';
import { TextField, Grid, Box, Alert } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';

import { resetPassword } from 'aws-amplify/auth';
import * as Yup from 'yup';

export default function SendRecoveryCodeForm(props) {
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Por favor, ingrese un correo electrónico válido')
      .required('Por favor, ingrese un correo electrónico')
  });

  const [changePasswordError, setChangePasswordError] = useState('');

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (data) => {
      resetPassword({ username: data.email })
        .then((data) => {
          console.log(data);
          props.onRecoveryCodeSent();
        })
        .catch((err) => {
          console.log(err);
          setChangePasswordError(err.message);
        });
      props.onUserEmailInsert(data.email);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Correo electrónico"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Enviar codigo
            </LoadingButton>
          </Grid>
        </Grid>
        {changePasswordError ? (
          <Box py={3}>
            <Alert severity="error">{changePasswordError}</Alert>
          </Box>
        ) : null}
      </Form>
    </FormikProvider>
  );
}
