import React, { useCallback, useRef, useState } from 'react';
import { Typography, LinearProgress, Grid, IconButton, Popover } from '@mui/material';
import { Icon } from '@iconify/react';
import infoIcon from '@iconify/icons-eva/info-outline';

import { Odontogram } from '@aroninsolutions/odontogram';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import { useParams } from 'react-router-dom';
import { odontogramQuery } from '../../../graphql/querys/odontogramQuery';

export function UserOdontogram() {
  const { id } = useParams();
  const [openInfo, setOpenInfo] = useState(false);
  const divRef = useRef(null);

  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const [
    updateOdontodiagram,
    {
      data: updateOdontodiagramData,
      loading: updateOdontodiagramLoading,
      error: updateOdontodiagramError
    }
  ] = useMutation(gql`
    mutation updateOdontodiagram($id: ID!, $medicalHistoryData: PatientMedicalHistoryInput!) {
      updateMedicalHistory(id: $id, medicalHistoryData: $medicalHistoryData) {
        medicalHistoryData {
          id_patient
          medical_antecedents
          odontodiagram
          notes
          attached_files
          family_antecedents
          oral_history
          allergies
          habits
        }
      }
    }
  `);

  const { data: pData, loading: dataLoading } = useQuery(odontogramQuery, {
    variables: { id },
    fetchPolicy: 'no-cache'
  });

  const handleUpdate = useCallback(
    (newState) => {
      updateOdontodiagram({
        variables: {
          id,
          medicalHistoryData: {
            odontodiagram: JSON.stringify(newState)
          }
        }
      });
    },
    [id, updateOdontodiagram]
  );

  if (dataLoading || !pData) {
    return (
      <>
        <Typography variant="h4" noWrap>
          Odontograma
        </Typography>
        <br />
        <Grid item xs={12}>
          <LinearProgress />
          <div style={{ filter: 'blur(10px)' }}>
            <Odontogram />
          </div>
        </Grid>
      </>
    );
  }
  const state = { ...JSON.parse(pData.medicalHistoryByPatientId.odontodiagram) };
  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Odontograma
            <IconButton>
              <Icon icon={infoIcon} onClick={handleOpenInfo} />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
      <div ref={divRef}>
        <Popover
          id={id}
          open={openInfo}
          anchorEl={divRef.current}
          onClose={handleCloseInfo}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
        >
          <Typography sx={{ p: 2 }}>
            Haga clic derecho en un diente para desplegar el menu de opciones (Haga clic fuera de
            este pop-up para cerrarlo)
          </Typography>
        </Popover>
      </div>

      <br />
      <Odontogram initialState={state} handleUpdate={handleUpdate} />
    </>
  );
}
