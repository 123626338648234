import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ style }) {
  return <Box component="img" src="/static/dentalByAronin_small.png" style={{ ...style }} />;
}
