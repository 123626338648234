import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { PatientDetailById } from 'graphql/querys/patientDetailByIdQuery';
import { updatePatientNotificationsMutation } from '../../../graphql/mutations/updatePatientNotifications';

export function UserDetailHeader(props) {
  const p = props.patient;
  const [updateNotifications, { loading }] = useMutation(updatePatientNotificationsMutation, {
    refetchQueries: [
      {
        query: PatientDetailById,
        variables: { id: p.id }
      }
    ],
    onError: (error) => {
      console.error('Error updating notifications:', error);
    }
  });
  const handleNotificationChange = (e) => {
    console.log('e', e.target.checked);
    updateNotifications({
      variables: {
        id: p.id,
        enable_mail_notifications: e.target.checked
      },
      onError: (error) => {
        console.error('Error updating notifications:', error);
        // You could add a snackbar/alert here to show the error
      }
    });
  };
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader title="Paciente" titleTypographyProps={{ variant: 'h3' }} />
      <CardContent>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <Grid container spacing={2} columns={{ xs: 6, sm: 12 }}>
            <Grid xs={6} sm={6} md={2}>
              Nombre
              <Typography variant="h6">{p.first_name}</Typography>
            </Grid>
            <Grid xs={6} sm={6} md={2}>
              Apellido
              <Typography variant="h6">{p.last_name}</Typography>
            </Grid>
            <Grid xs={12} sm={6} md={2}>
              Email
              <Typography variant="h6" style={{ overflowWrap: 'anywhere' }}>
                {p.email}
              </Typography>
            </Grid>
            <Grid xs={12} sm={6} md={2}>
              Cédula
              <Typography variant="h6" style={{ overflowWrap: 'anywhere' }}>
                {p.cedula}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ px: 0, pt: 0, pb: 2 }}>
          {/* Always render the checkbox, but wrap in Tooltip only if disabled */}
          {(() => {
            const isDisabled = loading || !p.email || p.email.trim() === '';
            const checkbox = (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={p.enabled_mail_notifications || false}
                    onChange={handleNotificationChange}
                    disabled={isDisabled}
                  />
                }
                label={
                  <Typography>
                    Activar recordatorios por email
                    <Typography variant="caption" display="block" color="textSecondary">
                      A este paciente se le enviará un correo un día antes de cada una de sus
                      consultas
                    </Typography>
                  </Typography>
                }
              />
            );

            return isDisabled ? (
              <Tooltip title="Tiene que ingresar un email válido en la historia médica para activar los recordatorios">
                {checkbox}
              </Tooltip>
            ) : (
              checkbox
            );
          })()}
        </Box>
      </CardContent>
    </Card>
  );
}
