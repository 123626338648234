import * as Yup from 'yup';
import { useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Alert,
  Modal,
  Typography,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [registerError, setRegisterError] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email('Por favor, ingrese un correo electrónico válido')
      .required('Por favor, ingrese un correo electrónico'),
    phone: Yup.string()
      .matches(/^\+\d\d/, {
        message:
          'Un número de teléfono debe comenzar con un signo más (+), seguido inmediatamente por el código del país. (p.ej. +58414xxxxxxx)'
      })
      .min(12, 'Su número de teléfono debe tener al menos 12 caracteres (p.ej. +58414xxxxxxx)')
      .required('Por favor, ingrese un número de teléfono'),
    password: Yup.string()
      .required('Por favor, ingrese una contraseña')
      .min(8, 'Su contraseña debe tener al menos 8 caracteres')
      .matches(/[a-z]/, { message: 'Su contraseña debe tener al menos una minúscula' })
      .matches(/[A-Z]/, { message: 'Su contraseña debe tener al menos una mayúscula' })
      .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, {
        message: 'Su contraseña debe tener al menos un carácter especial'
      })
      .matches(/^.*\d.*$/, { message: 'Su contraseña debe tener al menos un número' }),
    familyName: Yup.string()
      .required('Por favor, ingrese su apellido')
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Su apellido no puede contener números ni caracteres especiales'
      ),
    name: Yup.string()
      .required('Por favor, ingrese su nombre')
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Su apellido no puede contener números ni caracteres especiales'
      )
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '+58',
      password: '',
      familyName: '',
      name: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (data) => {
      setRegisterError('');
      try {
        const user = await signUp({
          username: data.email,
          password: data.password,
          options: {
            userAttributes: {
              family_name: data.familyName,
              name: data.name,
              phone_number: data.phone.replace(' ', '').replace('-', '')
            }
          }
        });
        setConfirmModal(true);
        // @ts-ignore
        window.dataLayer.push({ event: 'user_register', user: data.email });
      } catch (error) {
        setRegisterError(error.message);
        console.log('error signing up:', error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    'border-radius': '8px'
  };

  return (
    <>
      <Modal
        open={confirmModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ¡Muchas gracias por su interés en Dental by Aronin!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Para poder iniciar sesión necesitamos que revise su buzón de correo y siga las
            intrucciones enviadas. El correo ha sido enviado a: {formik.values.email}.
          </Typography>
          <Button onClick={() => navigate('/login', { replace: true })}>Entendido</Button>
        </Box>
      </Modal>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="name"
              type="email"
              label="Nombres"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              autoComplete="family-name"
              type="familyName"
              label="Apellidos"
              {...getFieldProps('familyName')}
              error={Boolean(touched.familyName && errors.familyName)}
              helperText={touched.familyName && errors.familyName}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Correo electrónico"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              autoComplete="phone"
              type="phone"
              label="Número de teléfono"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Contraseña"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            {registerError ? (
              <Box py={3}>
                <Alert severity="error">{registerError}</Alert>
              </Box>
            ) : null}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Registrarse
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
