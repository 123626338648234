import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { fromUTCdatetimeToLocalDate } from 'utils/formatTime';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import UnselectableListHead from '../user/UnselectableListHead';

PayableConsultationTableContent.propTypes = {
  toClose: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleFilterByName: PropTypes.func,
  handleRequestSort: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isConsultationNotFound: PropTypes.bool,
  handleClick: PropTypes.func,
  onAssignConsultationId: PropTypes.number,
  onAssignConsultationDate: PropTypes.string,
  onAssignConsultationDescription: PropTypes.string
};

export default function PayableConsultationTableContent({
  toClose,
  selected,
  filterName,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredConsultations,
  onAssignConsultationId,
  onAssignConsultationDate,
  onAssignConsultationDescription,
  page,
  rowsPerPage,
  emptyRows,
  isConsultationNotFound,
  handleClick
}) {
  const { id } = useParams();
  return (
    <>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <UnselectableListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredConsultations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const {
                  consultationId,
                  event,
                  consultationDescription,
                  payedAmount,
                  totalAmount,
                  amountToPay
                } = row;
                const isItemSelected = selected.indexOf(consultationId) !== -1;

                return (
                  <TableRow
                    onClick={(e) => {
                      console.log(e);
                    }}
                    hover
                    key={consultationId}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell align="left">{fromUTCdatetimeToLocalDate(event.begin)}</TableCell>
                    <TableCell align="left">{consultationDescription}</TableCell>
                    <TableCell align="left">$ {payedAmount}</TableCell>
                    <TableCell align="left">$ {amountToPay}</TableCell>
                    <TableCell align="left">$ {totalAmount}</TableCell>

                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        disabled={isItemSelected}
                        onClick={() => {
                          onAssignConsultationId(consultationId);
                          onAssignConsultationDate(event.begin);
                          onAssignConsultationDescription(consultationDescription);
                          toClose();
                        }}
                      >
                        {isItemSelected ? 'Asignado' : 'Asignar pago'}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isConsultationNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Mas que ${to}`}`
        }
        labelRowsPerPage="Columnas por pagina"
        count={filteredConsultations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
