import { Tooltip } from '@mui/material';

const ToolTipWrapper = ({ condition, children, title }) =>
  condition ? (
    <Tooltip title={title} followCursor>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default ToolTipWrapper;
