import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import PlusCircleFill from '@iconify/icons-eva/plus-circle-fill';
import SubmitButtonWrapper from 'components/Form/SubmitButtonWrapper';
import TextField from '../../Form/TextFieldWrapper';

const FORM_VALIDATION = yup
  .object()
  .shape({
    name: yup.string().required('El nombre del tratamiento es necesario'),
    price: yup.number().integer().min(0).required('El precio del tratamiento es necesario')
  })
  .required();

const date = new Date().toISOString().split('T');
console.log(date);

const INITAL_FORM_STATE = {
  name: '',
  price: ''
};

AddTreatmentsEntry.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
export function AddTreatmentsEntry(props) {
  return (
    <Formik
      initialValues={INITAL_FORM_STATE}
      validationSchema={FORM_VALIDATION}
      onSubmit={(_values, { resetForm }) => {
        props.onSubmit({
          name: _values.name,
          price: parseInt(_values.price, 10)
        });

        resetForm({ values: INITAL_FORM_STATE });
      }}
    >
      {({ errors }) => (
        <>
          <Grid
            container
            rowSpacing={5}
            columnSpacing={3}
            columns={{ xs: 6, sm: 6, md: 12 }}
            p={{ xs: 2, sm: 3 }}
          >
            <Grid item xs={7}>
              <TextField name="name" label="Nombre del tratamiento" fullWidth size="small" />
            </Grid>
            <Grid item xs={3}>
              <TextField name="price" label="Precio" type="number" fullWidth size="small" />
            </Grid>
            <Grid item xs={2}>
              <SubmitButtonWrapper
                disabled={Object.keys(errors).length > 0}
                startIcon={<Icon icon={PlusCircleFill} />}
              >
                Agregar
              </SubmitButtonWrapper>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
