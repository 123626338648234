import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

const SubmitButtonWrapper = ({ children, onUserFormSubmit, ...otherProps }) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    if (onUserFormSubmit) {
      onUserFormSubmit();
    }
    console.log('submitButtonClicked');
    submitForm();
  };

  const configSubmitButton = {
    onClick: handleSubmit,
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    ...otherProps
  };

  return <Button {...configSubmitButton}>{children}</Button>;
};

export default SubmitButtonWrapper;
