const HandleFocusAndBlur = (props) => (
  <div
    onFocus={() => {
      if (props.disabled) {
        props.event(true);
      }
    }}
    onBlur={() => {
      if (props.disabled) {
        props.event(false);
      }
    }}
  >
    {props.children}
  </div>
);

export default HandleFocusAndBlur;
