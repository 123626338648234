import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { deleteConsultationFileMutation } from '../../graphql/mutations/deleteConsultationFile';
import { consultationDataFilesQuery } from '../../graphql/querys/consultationDataFilesQuery';
import { generateConsultationDataDownloadURL } from '../../graphql/querys/generateConsultationDataDonwloadUrlQuery';
import { FileModal } from './FileModal'; // Import the FileModal component
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead } from '../_dashboard/user';
import ContextFileMenu from './ContextFileMenu';

// DeleteConfirmDialog Component
function DeleteConfirmDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Quiere eliminar este archivo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>Eliminar</Button>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired
};

ConsultationFilesTableContent.propTypes = {
  selected: PropTypes.array.isRequired,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleFilterByName: PropTypes.func,
  handleRequestSort: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isFileNotFound: PropTypes.bool,
  TABLE_HEAD: PropTypes.array,
  filteredFiles: PropTypes.array,
  handleClick: PropTypes.func
};

export default function ConsultationFilesTableContent({
  selected,
  filterName,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredFiles,
  page,
  rowsPerPage,
  emptyRows,
  isFileNotFound
}) {
  const { consultationId } = useParams();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileUrl, setFileUrl] = useState('');

  const [deleteConsultationFile] = useMutation(deleteConsultationFileMutation, {
    refetchQueries: [{ query: consultationDataFilesQuery, variables: { consultationId } }]
  });

  const [generateConsultationDataDownloadURLQuery] = useLazyQuery(
    generateConsultationDataDownloadURL
  );

  const handleOpenFile = async (key) => {
    const result = await generateConsultationDataDownloadURLQuery({
      variables: {
        consultationId,
        fileId: key
      }
    });

    const url = result.data.generateConsultationDataDownloadURL;
    setFileUrl(url);
    setOpenFileModal(true);
  };

  const handleDeleteSelected = () => {
    setDeleteConfirmModal(false);
    deleteConsultationFile({
      variables: {
        consultationId,
        fileIds: [fileToDelete]
      }
    }).then(({ data }) => {
      console.log('this is the result', data);
      setFileToDelete(null);
    });
  };

  const handleDelete = (fileId) => {
    setFileToDelete(fileId);
    setDeleteConfirmModal(true);
  };

  return (
    <>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredFiles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const { name, date, mimeType, key } = row;
                const isItemSelected = selected.indexOf(key) !== -1;

                return (
                  <TableRow
                    onClick={() => handleOpenFile(key)}
                    hover
                    key={key}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{date}</TableCell>
                    <TableCell align="left">{mimeType}</TableCell>

                    <TableCell align="right">
                      <ContextFileMenu handleDelete={() => handleDelete(key)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isFileNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Mas que ${to}`}`
        }
        labelRowsPerPage="Columnas por pagina"
        count={filteredFiles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DeleteConfirmDialog
        open={deleteConfirmModal}
        onClose={() => setDeleteConfirmModal(false)}
        onConfirm={handleDeleteSelected}
        itemCount={selected.length}
      />
      <FileModal
        open={openFileModal}
        onClose={() => setOpenFileModal(false)}
        presignedDownloadUrl={fileUrl} // Pass the URL to the modal
      />
    </>
  );
}
