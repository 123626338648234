import { useLazyQuery, useQuery } from '@apollo/client';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { fetchUserAttributes } from 'aws-amplify/auth';
import Page from 'components/Page';
import SelectUser from 'components/_dashboard/user/SelectUser';
import { Calendar } from 'components/appointments/Calendar';
import { toFullCalendarEvent } from 'components/appointments/types';
import { groupUsersQuery } from 'graphql/querys/groupUsers';
import { searchEventsQuery } from 'graphql/querys/searchEvents';
import { useEffect, useState } from 'react';
import { formatCognitoUsers } from 'utils/formatCognitoUsers';

export default function Scheduler() {
  const [mySub, setMySub] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [searchEvents, { data: eventsQuery, loading: eventsQueryLoading }] =
    useLazyQuery(searchEventsQuery);

  useEffect(() => {
    searchEvents({
      variables: {
        mandatory_presence_contains: selectedDoctor === 'all' ? null : selectedDoctor
      }
    });
  }, [selectedDoctor]);

  if (!mySub) {
    fetchUserAttributes().then(({ sub }) => {
      setMySub(sub);
      setSelectedDoctor(mySub);
      console.log('setting default sub');
    });
  }

  const { data: groupUsers } = useQuery(groupUsersQuery);
  let formattedUsers = { all: 'Todos' };
  // @ts-ignore
  if (groupUsers) formattedUsers = formatCognitoUsers(groupUsers.getGroupUsers, formattedUsers);

  const handleDoctorChange = (event) => {
    const sub = event.target.value;
    setSelectedDoctor(sub);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const formattedEvents = eventsQuery ? eventsQuery.searchEvents.map(toFullCalendarEvent) : [];

  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Calendario
          </Typography>
          <FormControl sx={{ width: 300 }}>
            <InputLabel id="doctor-label">Calendario</InputLabel>
            <Select
              sx={{ maxWidth: 300 }}
              label="doctor-label"
              labelId="doctor-label"
              id="doctor"
              value={selectedDoctor}
              onChange={handleDoctorChange}
            >
              {Object.keys(formattedUsers).map((sub) => (
                <MenuItem key={sub} value={sub}>
                  {formattedUsers[sub]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleClickOpen}
          >
            Nueva consulta
          </Button>
          <Dialog
            PaperProps={{ sx: { width: '100%', height: '100%' } }}
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
          >
            <SelectUser toClose={handleClose} />
          </Dialog>
        </Stack>
        <Card sx={{ boxShadow: 3, padding: 3 }}>
          <Calendar events={formattedEvents} />
          {eventsQueryLoading && <p>Searching for events...</p>}
        </Card>
      </Container>
    </Page>
  );
}
