import { motion } from 'framer-motion';
// material
import { Box, Typography, Container } from '@mui/material';
import { MotionContainer, varBounceIn } from './animate';
export default function NotInAGroup() {
  return (
    <Container>
      <MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounceIn}>
            <Typography variant="h3" paragraph>
              ¡Gracias por crear tu cuenta con nosotros!
            </Typography>
          </motion.div>
          <Typography sx={{ color: 'text.secondary' }}>
            Ya hemos recibido una notificación y estamos en proceso de activar tu cuenta para que
            puedas acceder a todas las funciones de la plataforma.
            <br />
            <br />
            Nos pondremos en contacto contigo muy pronto{' '}
            <span role="img" aria-label="heart">
              ❤️
            </span>
          </Typography>
          <motion.div variants={varBounceIn}>
            <Box
              component="img"
              src="/static/illustrations/illustration_register.png"
              sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />
          </motion.div>
        </Box>
      </MotionContainer>
    </Container>
  );
}
