import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead } from '../user';
//

BillsTableContent.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleFilterByName: PropTypes.func,
  handleRequestSort: PropTypes.func,
  handleSelectAllClick: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isConsultationNotFound: PropTypes.bool,
  handleClick: PropTypes.func
};

export default function BillsTableContent({
  selected,
  filterName,
  handleRequestSort,
  handleSelectAllClick,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredUsers,
  page,
  rowsPerPage,
  emptyRows,
  isConsultationNotFound
}) {
  return (
    <>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={filteredUsers.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const { id, firstName, lastName, email, phoneNumber, totalAmountToPay } = row;
                const isItemSelected = selected.indexOf(id) !== -1;

                return (
                  <TableRow
                    onClick={(e) => {
                      console.log(e);
                    }}
                    hover
                    component={RouterLink}
                    to={id}
                    sx={{ textDecoration: 'none', color: 'inherit' }}
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell align="left">
                      {firstName} {lastName}
                    </TableCell>
                    <TableCell align="left">{email}</TableCell>
                    <TableCell align="left">{phoneNumber}</TableCell>
                    <TableCell align="left">$ {totalAmountToPay}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isConsultationNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Mas que ${to}`}`
        }
        labelRowsPerPage="Columnas por pagina"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
