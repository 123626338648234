import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import * as Sentry from '@sentry/browser';
import { getCurrentUser } from 'aws-amplify/auth';

const AuthContext = React.createContext();
const UpdateAuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function useAuthUpdate() {
  return useContext(UpdateAuthContext);
}

export function AuthProvider({ children }) {
  const updateAuth = (bool) => localStorage.setItem('token', bool);

  const ifAuthTrue = () => {
    const isTokenPresent = localStorage.getItem('token') === 'true';
    if (isTokenPresent) {
      getCurrentUser().then(({ username, userId, signInDetails }) => {
        Sentry.setContext('session', { username, userId, signInDetails });
      });
    }
    return isTokenPresent;
  };

  return (
    <AuthContext.Provider value={ifAuthTrue}>
      <UpdateAuthContext.Provider value={updateAuth}>{children}</UpdateAuthContext.Provider>
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node
};
