import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import { fetchAuthSession } from 'aws-amplify/auth';
import DashboardNavbar from './DashboardNavbar';
import NotInAGroupMessage from '../../components/NotInAGroupMessage';

import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [isInAGroup, setIsInAGroup] = useState(true);

  const isInsideManager = window.location.pathname.includes("/manager")

  fetchAuthSession().then((authSession) => {
    const cognitoGroups = authSession.tokens.accessToken.payload['cognito:groups'];
    if (!cognitoGroups) {
      setIsInAGroup(false);
    }
  });

  const isLoggingOut = window.location.pathname.includes('logout');

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>{isInAGroup || isLoggingOut || isInsideManager ? <Outlet /> : <NotInAGroupMessage />}</MainStyle>
    </RootStyle>
  );
}
