import { gql } from '@apollo/client';

export const updatePatientNotificationsMutation = gql`
  mutation updatePatientNotifications(
    $id: ID!
    $enable_mail_notifications: Boolean!
  ) {
    updatePatientNotifications(
      id: $id
      enable_mail_notifications: $enable_mail_notifications
    ) {
      patientNotificationsData {
        id
        enabled_mail_notifications
      }
    }
  }
`;
