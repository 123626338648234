// @ts-nocheck
import { useMutation } from '@apollo/client';
import { Skeleton } from '@mui/material';
import { filter } from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PayableConsultationTableContent from './PayableConsultationTableContent';
import ListToolbar from '../user/ListToolbar';
import { revertFormattedDate, getComparator } from '../../../utils/comparator';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'event.begin', label: 'Fecha', alignRight: false },
  { id: 'consultationDescription', label: 'Motivo de la consulta', alignRight: false },
  { id: 'payedAmount', label: 'Monto cancelado', alignRight: false },
  { id: 'amountToPay', label: 'Monto por cancelar', alignRight: false },
  { id: 'totalAmount', label: 'Monto total', alignRight: false }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });
  //  Pass query as parameter for front side search
  if (query) {
    return filter(
      array,
      (_consultation) =>
        `${_consultation.consultationDescription.toLowerCase()}`.indexOf(query.toLowerCase()) !==
          -1 || `${_consultation.event.begin}`.indexOf(query) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export function PayableConsultations(props) {
  const { owedConsultations, selectedId } = props;
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([selectedId]);
  const [orderBy, setOrderBy] = useState('lastName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { id } = useParams();

  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [displayDeleteError, setDisplayDeleteError] = React.useState(null);
  const [displayDeleteSuccess, setDisplayDeleteSuccess] = React.useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!owedConsultations) {
    return <Skeleton animation="wave" />;
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - owedConsultations.length) : 0;

  const filteredConsultations = applySortFilter(
    owedConsultations,
    getComparator(order, orderBy),
    filterName
  );

  const isConsultationNotFound = filteredConsultations.length === 0;
  return (
    <>
      <ListToolbar
        singular="consulta seleccionada"
        plural="consultas seleccionadas"
        numSelected={0}
        filterName={filterName}
        onFilterName={handleFilterByName}
        placeholder="Buscar consulta..."
      />
      <PayableConsultationTableContent
        toClose={props.toClose}
        onAssignConsultationId={props.setConsultationId}
        onAssignConsultationDescription={props.setConsultationDescription}
        onAssignConsultationDate={props.setConsultationDate}
        selected={selectedId}
        filterName={filterName}
        handleFilterByName={handleFilterByName}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        TABLE_HEAD={TABLE_HEAD}
        filteredConsultations={filteredConsultations}
        page={page}
        rowsPerPage={rowsPerPage}
        emptyRows={emptyRows}
        isConsultationNotFound={isConsultationNotFound}
        handleClick={handleClick}
      />
    </>
  );
}
