import { useQuery } from '@apollo/client';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// components
import { dateFormater } from '../../../utils/dateFormater';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
//
import { patientsCount } from '../../../graphql/querys/patientsCount';
import SelectedUserListHead from './SelectedUserListHead';

SelectUserTableContent.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleFilterByName: PropTypes.func,
  handleRequestSort: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isUserNotFound: PropTypes.bool,
  handleClick: PropTypes.func
};

export default function SelectUserTableContent({
  filterName,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredUsers,
  page,
  rowsPerPage,
  emptyRows,
  isUserNotFound,
  handleClick,
  setRowsPerPage
}) {
  const { data: countData, loading: countLoading, error: countError } = useQuery(patientsCount);
  return (
    <>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <SelectedUserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={filteredUsers.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const { id, firstName, lastName, cedula, email, dateOfBirth, sex } = row;
                const gender = translateSex(sex);
                const formatedDateOfBirth = dateFormater(
                  new Date(Number(dateOfBirth)).toISOString().split('T')[0]
                );

                return (
                  <TableRow
                    onClick={(e) => {
                      console.log(e);
                    }}
                    component={RouterLink}
                    to={`/dashboard/createConsultation/${id}`}
                    hover
                    sx={{ textDecoration: 'none', color: 'inherit', alignItems: 'center' }}
                    key={id}
                    tabIndex={-1}
                  >
                    <TableCell scope="row" padding="normal">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="subtitle2" noWrap>
                          {`${firstName} ${lastName}`}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{cedula}</TableCell>
                    <TableCell align="left">{email}</TableCell>
                    <TableCell align="left">{formatedDateOfBirth}</TableCell>
                    <TableCell align="left">{gender}</TableCell>
                    {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(status === 'banned' && 'error') || 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell> */}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isUserNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[
          5,
          10,
          25,
          {
            label: 'Todos',
            value: typeof countData === 'undefined' ? 100 : countData.getPatientsCount
          }
        ]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Mas que ${to}`}`
        }
        labelRowsPerPage="Pacientes por pagina"
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

function translateSex(sex) {
  if (sex === 'male') {
    return 'Masculino';
  }
  if (sex === 'female') {
    return 'Femenino';
  }
  return 'Otro';
}
