import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink, useParams } from 'react-router-dom';
import React from 'react';
import { ConsultationsByPatientId } from 'graphql/querys/consultationsByPatientIdQuery';
import { ConsultationsOwedByPatientId } from 'graphql/querys/consultationsOwedByPatientIdQuery';
import { DeleteConsultations } from 'graphql/mutations/deleteConsultations';
import { consultationsByTreatmentPlanId } from 'graphql/querys/consultationsByTreatmentPlanIdQuery';
import { treatmentPlansByPatientId } from 'graphql/querys/treatmentPlansByPatientId';
import { useMutation } from '@apollo/client';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead } from '../user';
import ContextConsultationMenu from './ContextConsultationMenu';

const FirstConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        ¿Está seguro que quiere eliminar esta consulta?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm}>Eliminar</Button>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
);

const SecondConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Toda la información relacionada a esta consulta será eliminada y no podrá ser recuperada,
        ¿desea continuar?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm}>Eliminar</Button>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
);

FirstConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

SecondConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

ConsultationTableContent.propTypes = {
  filterName: PropTypes.string,
  filteredConsultations: PropTypes.array,
  handleRequestSort: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isConsultationNotFound: PropTypes.bool,
  TABLE_HEAD: PropTypes.array,
  treatmentPlanId: PropTypes.string
};

export default function ConsultationTableContent({
  filterName,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredConsultations,
  page,
  rowsPerPage,
  emptyRows,
  isConsultationNotFound,
  treatmentPlanId
}) {
  const { id } = useParams();
  const [internalTreatmentPlanId, setinternalTreatmentPlanId] = React.useState(null);
  const [deleteConsultations] = useMutation(DeleteConsultations, {
    refetchQueries: [
      { query: ConsultationsByPatientId, variables: { consultationsByPatientIdId: id } },
      { query: treatmentPlansByPatientId, variables: { id } },
      { query: ConsultationsOwedByPatientId, variables: { id } },
      ...(treatmentPlanId
        ? [
            {
              query: consultationsByTreatmentPlanId,
              variables: { consultationsByTreatmentPlanIdId: treatmentPlanId }
            }
          ]
        : []),
      ...(internalTreatmentPlanId
        ? [
            {
              query: consultationsByTreatmentPlanId,
              variables: { consultationsByTreatmentPlanIdId: internalTreatmentPlanId }
            }
          ]
        : [])
    ]
  });

  const [deleteFirstConfirmModal, setDeleteFirstConfirmModal] = React.useState(false);
  const [deleteSecondConfirmModal, setDeleteSecondConfirmModal] = React.useState(false);
  const [selectedConsultation, setSelectedConsultation] = React.useState(null);
  const [displayDeleteError, setDisplayDeleteError] = React.useState(null);
  const [displayDeleteSuccess, setDisplayDeleteSuccess] = React.useState(null);

  const handleDeleteSelected = () => {
    setDeleteFirstConfirmModal(false);
    setDeleteSecondConfirmModal(false);
    deleteConsultations({
      variables: {
        patientId: id,
        ids: [selectedConsultation]
      }
    }).then(({ data }) => {
      console.log(data);
      if (data.deleteConsultations?.paymentError) {
        if (data.deleteConsultations.paymentError.field === 'Payments found') {
          setDisplayDeleteError(
            'Esta consulta tiene un pago asignado, para eliminar es necesario que el pago sea eliminado primero.'
          );
        } else {
          setDisplayDeleteError(data.deleteConsultations.paymentError.message);
        }
      } // Added missing closing brace here
      if (data.deleteConsultations?.paymentData) {
        setDisplayDeleteSuccess(`la consulta ha sido eliminada correctamente`);
      }
    });
    setSelectedConsultation(null);
  };

  const handleDelete = (consultationId, rowTreatmentPlanId) => {
    setinternalTreatmentPlanId(rowTreatmentPlanId); // this is used if the consultation is deleted from the main consultation screen and not the treatment plan screen
    setSelectedConsultation(consultationId);
    setDeleteFirstConfirmModal(true);
  };

  return (
    <>
      {displayDeleteError && (
        <Alert variant="outlined" severity="error">
          {displayDeleteError}
        </Alert>
      )}
      {displayDeleteSuccess && (
        <Alert variant="outlined" severity="success">
          {displayDeleteSuccess}
        </Alert>
      )}
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredConsultations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const { consultationId, event, consultationDescription } = row;

                return (
                  <TableRow
                    component={RouterLink}
                    to={`/dashboard/consultation/${id}/${consultationId}`}
                    sx={{ textDecoration: 'none', color: 'inherit' }}
                    hover
                    key={consultationId}
                    tabIndex={-1}
                  >
                    <TableCell align="left">{event.begin}</TableCell>
                    <TableCell align="left">{consultationDescription}</TableCell>
                    <TableCell align="left">
                      {event.state === 'done' ? 'Realizada' : 'Agendada'}
                    </TableCell>
                    <TableCell align="left">
                      <ContextConsultationMenu
                        handleDelete={() => {
                          handleDelete(consultationId, row.treatmentPlanId);
                          setDisplayDeleteError(null);
                          setDisplayDeleteSuccess(null);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isConsultationNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} onClick={(e) => e.stopPropagation()} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Más que ${to}`}`
        }
        labelRowsPerPage="Columnas por pagina"
        count={filteredConsultations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <FirstConfirmDialog
        open={deleteFirstConfirmModal}
        onClose={() => setDeleteFirstConfirmModal(false)}
        onConfirm={() => {
          setDeleteFirstConfirmModal(false);
          setDeleteSecondConfirmModal(true);
        }}
      />
      <SecondConfirmDialog
        open={deleteSecondConfirmModal}
        onClose={() => setDeleteSecondConfirmModal(false)}
        onConfirm={handleDeleteSelected}
      />
    </>
  );
}
